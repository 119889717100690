import angular from 'angular';
import { importFilesUnderPath, templateCacheGenerator } from 'roblox-es6-migration-helper';

import "../../../css/groupSearch.scss";

//import main module definition.
import groupSearchModule from './groupSearchModule';

importFilesUnderPath(require.context("./components/", true, /\.js$/));
importFilesUnderPath(require.context("./constants/", true, /\.js$/));
importFilesUnderPath(require.context("./controllers/", true, /\.js$/));
importFilesUnderPath(require.context("./directives/", true, /\.js$/));
importFilesUnderPath(require.context("./services/", true, /\.js$/));


let templateContext = require.context("./", true, /\.html$/);

const templates = templateCacheGenerator(angular, 'groupSearchAppHtmlTemplateApp', templateContext);

// self manual initialization
angular.element(function () {
    angular.bootstrap("#group-search-container", [groupSearchModule.name, templates.name]);
});

export default groupSearchModule;