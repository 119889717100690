import groupSearchModule from '../groupSearchModule';

const groupLanding = {
  templateUrl: 'group-landing',
  bindings: {
    myGroups: '<',
    search: '=',
    showAllFriendsGroups: '=',
    showAllMyGroups: '=',
    showCreateGroupButton: '<',
    handleCreateGroupClick: '<',
    handleViewGroupDetailsClick: '<',
    isV2: '<'
  },
  controller: 'groupLandingController'
};

groupSearchModule.component('groupLanding', groupLanding);

export default groupLanding;
