import groupSearchModule from '../groupSearchModule';
import { urlService } from 'core-utilities';

function groupSearchBarController(groupsConstants, keyCode, $scope, $window) {
  'ngInject';

  $scope.keyCodes = keyCode;
  $scope.data = {
    searchTerm: '',
    keyword: '',
    moreGroupsUrl: groupsConstants.absoluteUrls.moreGroups
  };

  $scope.searchGroups = () => {
    $window.location.href = urlService.getAbsoluteUrl(`/search/groups?keyword=${$scope.data.keyword}`);
  };

  $scope.updateKeyword = event => {
    // manually updating the model here because android webviews don't update ngmodel quick enough
    $scope.data.keyword = event.target.value;
  };
}

groupSearchModule.controller('groupSearchBarController', groupSearchBarController);

export default groupSearchBarController;
