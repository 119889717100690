import groupSearchModule from '../groupSearchModule';

function groupSearchBar(groupResources) {
    "ngInject";
    return {
        restrict: "A",
        scope: true,
        templateUrl: groupResources.templates.groupSearchBarTemplate
    }
};

groupSearchModule.directive("groupSearchBar", groupSearchBar);

export default groupSearchBar;